import axios from 'axios';
import moment from 'moment';
import { Component } from 'react';
import { Row, Col, Button, Table, OverlayTrigger,Tooltip     } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { getSellerHeaders, getSellerUrl } from 'shared/http';
import { membershipMetrics } from 'shared/settings/apiconfig';
import Excel from 'assets/CSV.svg';
import './MembershipMetrics.scss';

export class MembershipMetrics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: {
                fromdate: '',
                todate: ''
            },
            errorMsg: false,
            metricsData: {},
            pageLoading: false,
            searchDisable: false
        }
    }

    /* set date for date input change */
    setDate = (control, date) => {
        this.setState({
            ...this.state,
            issearch: true,
            search: {
                ...this.state.search,
                [control]: date,
            }
        });
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    /* called when clear all in search clicked */
    resetState = () => {
        this.setState({
            search: {
                fromdate: '',
                todate: ''
            },
            metricsData: '',
            errorMsg: false
        });
    }

    enableSearch = () => {
        let startDate = null
        let todate =  null

        if(this.state.search.fromdate){
            let startDateFormat = moment(this.state.search.fromdate).format("YYYY-MM-DD")
            startDate =  moment.tz(startDateFormat + ' 00:00:00', "US/Eastern").utc().format("YYYY-MM-DD hh:mm:ss")
        }
        
        if(this.state.search.todate){
            let endDateformat = moment(this.state.search.todate).format("YYYY-MM-DD")
            todate =  moment.tz(endDateformat + ' 23:59:59', "US/Eastern").utc().format("YYYY-MM-DD hh:mm:ss")
        }
        this.setState({
            pageLoading: true,
            searchDisable: true
        })
        let data = {
            "data": {
                "fromdate": startDate ?  startDate : '',
                "todate": todate ? todate : '',
            }
        }
        axios.post(getSellerUrl(membershipMetrics, {}, true), JSON.stringify(data), getSellerHeaders()).then((res) => {
            this.setState({
                metricsData: res.data.data,
                pageLoading: false,
                searchDisable: false
            });
        }).catch((error) => {
            this.setState({
                errorMsg: true,
                pageLoading: false,
                searchDisable: false
            })
        })
    }

    onclickCSV = () => {
        //set Membership metrics data
        let csvRow = ""
        let csvHeader = ""
        let columns = [
            { name: 'Newly Enrolled Members', value: this.state.metricsData.newlyenrolledmembers },
            { name: 'Number of Coupon Codes Used', value: this.state.metricsData.couponcodesused },
            { name: 'Total Revenue', value: this.state.metricsData.totalrevenue },
            { name: 'Delivery Guarantee Credits Used', value: this.state.metricsData.creditsused },
            { name: 'Delivery Guarantee Credit Value', value: this.state.metricsData.creditsvalues },
        ]

        let csvHeaderItem = []
        let csvRowItem = []
        columns.map((col, key) => {
            csvHeaderItem.push(col["name"])
            if( col["name"] == "Total Revenue" || col["name"] == "Delivery Guarantee Credit Value" ) {
                csvRowItem.push('$ ' + col["value"]);
            } else {
                typeof col["value"] === 'string' ? csvRowItem.push('"' + col["value"].replace(/"/g, '""') + '"') : csvRowItem.push(col["value"]);
            }
        })
        csvHeader = csvHeaderItem.map(elem => { return '"' + elem + '"' }).join(",") + '\r\n'
        csvRow = csvRow + csvRowItem.join(",") + '\r\n'

        //csv download section
        let BOM = "\uFEFF";
        let csvData = BOM + csvHeader + csvRow
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', `membership-metrics.csv`);
        a.click()
    }

    render() {

        return (
            <div style={{ overflow: "auto", overflowY: "overlay", height: "100%" }}>
                <div className="sale-search">
                    <div className="sales-list-search">
                        <div className="sales-search-container">
                            <Row className="d-flex flex-row w-100">
                                <Col className="d-flex flex-column text-secondary">
                                    <small>Membership Purchase Date From</small>
                                    <div>
                                        <label style={{ width: "100%" }} onClick={e => this.start.state.open && e.preventDefault()}>
                                            <DatePicker ref={r => this.start = r} className="datePicker" dateFormat="MM/dd/yyyy" value={this.state.search.fromdate} selected={this.state.search.fromdate} onChange={date => { this.setDate('fromdate', date) }} onChangeRaw={this.handleDateChangeRaw} peekNextMonth showMonthDropdown showYearDropdown placeholderText="MM/DD/YYYY" dropdownMode="select" />
                                            <i style={{ position: "absolute", top: "48%", right: "12%", color: "#00263E" }} className="fa fa-calendar" aria-hidden="true"></i>
                                        </label>
                                    </div>
                                </Col>
                                <Col className="d-flex flex-column text-secondary">
                                    <small>Membership Purchase Date To</small>
                                    <div>
                                        <label style={{ width: "100%" }} onClick={e => this.end.state.open && e.preventDefault()}>
                                            <DatePicker minDate={this.state.search.fromdate ? this.state.search.fromdate : ''} ref={r => this.end = r} className="datePicker" dateFormat="MM/dd/yyyy" value={this.state.search.todate} selected={this.state.search.todate} onChange={date => { this.setDate('todate', date) }} onChangeRaw={this.handleDateChangeRaw} peekNextMonth showMonthDropdown showYearDropdown placeholderText="MM/DD/YYYY" dropdownMode="select" />
                                            <i style={{ position: "absolute", top: "48%", right: "12%", color: "#00263E" }} className="fa fa-calendar" aria-hidden="true"></i>
                                        </label>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="mt-3">
                                        <Button className="clear-button" onClick={() => this.resetState()}>Clear All</Button>
                                        <Button variant="search-button" disabled={!this.state.search.fromdate || !this.state.search.todate || this.state.searchDisable} onClick={() => this.enableSearch()}>Search</Button>
                                    </div>
                                </Col>
                                <Col></Col>
                            </Row>
                        </div>
                    </div>
                </div>
                { 
                    this.state.pageLoading ? 
                    <div className="d-flex flex-column align-items-center justify-content-center" style={{ padding: "15%" }}>
                        <img src={`${process.env.PUBLIC_URL}/static/icons/table/option_1.gif`} alt="default" height="100px" width="100px" />
                    </div> : 
                    Object.keys(this.state.metricsData).length > 0 ?
                    <div>
                        <div style={{ float: 'right', marginTop: "3px" }}>
                            <div >
                                <Button onClick={() => this.onclickCSV()} className="button-view" style={{ float: "right" }}>
                                    <div className="button-text" >
                                        <img src={Excel} width="25px" height="25px" alt="excel" />Export to CSV</div>
                                </Button>
                            </div>
                        </div>
                        <div className='mt-3'></div>
                        <div className='p-3' style={{ backgroundColor: '#BBC7D6' }}>
                            <div className="mb-3 mt-3" style={{ fontWeight: 'bold', fontSize: '15px', textAlign: 'left' }}>
                                Membership Metrics :
                                <span className='border-set' style={{ width: '150px' }}></span>
                            </div>
                            <div className='admin-report'>
                                <Table className="amountfields" responsive style={{ height: "100%" }}>
                                    <thead>
                                        <tr style={{ color: "#00263E", flexWrap: "nowrap", fontWeight: 'bold' }}>
                                            <td className='td-color'>Newly Enrolled Members
                                            <span style={{ marginLeft: '2%', marginTop: '1.5px'}}>
                                               <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip-clone" style={{ visibility: 'visible' }}>{'New members enrolled for the specified date range'}</Tooltip>}>
                                                     <i className={`fa fa-question-circle`} style={{ fontSize: "14px" }}></i> 
                                                </OverlayTrigger>
                                            </span>
                                            </td>
                                           
                                            <td className='td-color'>Number of Coupon Codes Used
                                            <span style={{ marginLeft: '2%', marginTop: '1.5px'}}>
                                               <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip-clone" style={{ visibility: 'visible' }}>{'Number of coupons used within the specified date range.'}</Tooltip>}>
                                                     <i className={`fa fa-question-circle`} style={{ fontSize: "14px" }}></i> 
                                                </OverlayTrigger>
                                            </span>
                                            </td>
                                            <td className='td-color'>Total Revenue
                                            <span style={{ marginLeft: '2%', marginTop: '1.5px'}}>
                                               <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip-clone" style={{ visibility: 'visible' }}>{'Total revenue from premier membership.'}</Tooltip>}>
                                                     <i className={`fa fa-question-circle`} style={{ fontSize: "14px" }}></i> 
                                                </OverlayTrigger>
                                            </span>
                                            </td>
                                            <td className='td-color'>Delivery Guarantee Credits Used
                                            <span style={{ marginLeft: '2%', marginTop: '1.5px'}}>
                                               <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip-clone" style={{ visibility: 'visible' }}>{'Number of credits used by the members within the specified date range'}</Tooltip>}>
                                                     <i className={`fa fa-question-circle`} style={{ fontSize: "14px" }}></i> 
                                                </OverlayTrigger>
                                            </span>
                                            </td>
                                            <td className='td-color'>Delivery Guarantee Credit Value
                                            <span style={{ marginLeft: '2%', marginTop: '1.5px'}}>
                                               <OverlayTrigger trigger={['hover', 'focus']} overlay={<Tooltip id="tooltip-clone" style={{ visibility: 'visible' }}>{'Seel value of All DG Invoices paid within the specified date range where member credits are used.'}</Tooltip>}>
                                                     <i className={`fa fa-question-circle`} style={{ fontSize: "14px" }}></i> 
                                                </OverlayTrigger>
                                            </span>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tr>
                                        <td className='td-color'>{this.state.metricsData.newlyenrolledmembers ? this.state.metricsData.newlyenrolledmembers : '- - -'}</td>
                                        <td className='td-color'>{this.state.metricsData.couponcodesused ? this.state.metricsData.couponcodesused : '- - -'}</td>
                                        <td className='td-color'>{this.state.metricsData.totalrevenue ? "$ "+`${this.state.metricsData.totalrevenue}` : '- - -'}</td>
                                        <td className='td-color'>{this.state.metricsData.creditsused ? this.state.metricsData.creditsused : '- - -'}</td>
                                        <td className='td-color'>{this.state.metricsData.creditsvalues ? "$ "+`${this.state.metricsData.creditsvalues}` : '- - -'}</td>
                                    </tr>
                                </Table>
                            </div>
                        </div>
                    </div> :
                    <div>
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "54vh" }}>
                            <h5>{this.state.errorMsg ? `No results found, please try your search again` : `Provide criteria to filter your search `}</h5>
                        </div>
                    </div> }
            </div>
        );
    }
}