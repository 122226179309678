// library imports
import { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Member } from 'features/components/base/membership/member';
import { Membership } from 'features/components/base/membership/Membership';
import { MembershipMetrics } from 'features/components/base/membership/MembershipMetrics';

export default class MembershipRouter extends Component {

    render() {
        return (
            <Switch>
                <Route exact path={`/membership`} component={Membership} />
                <Route exact path={`/membership/members`} component={Member} />
                <Route exact path={`/membership/metrics`} component={MembershipMetrics} />
                <Redirect to="/not-found" />
            </Switch>
        );
    }
}


